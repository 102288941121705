/* eslint-disable */
/* prettier-ignore */

const aws_exports = {
	"Auth": {
		"region": "us-east-1",
		"userPoolId": "us-east-1_Y4fRmtpSM",
		"userPoolWebClientId": "71sprpcv5gldmvnohg66c5s06n"
	},
	// S3 bucket for uploading Admin CSVs
	"Storage": {
		"AWSS3": {
			"bucket": "ccic2020-team-uploadusers",
			"region": "us-west-2"
		}
	},
	// Add in new API, "name" can be whatever one wants
	"API": {
		"endpoints": [
			{
				"name": "code",
				"endpoint": "https://xuuh2hbq9h.execute-api.us-west-2.amazonaws.com/prod"
			}
		]
	},
	"aws_project_region": "us-east-1",
	"aws_cognito_identity_pool_id": "us-east-1:29c1ddd5-de98-434b-9b96-c101edf78500",
	"aws_cognito_region": "us-east-1",
	"aws_user_pools_id": "us-east-1_Y4fRmtpSM",
	"aws_user_pools_web_client_id": "71sprpcv5gldmvnohg66c5s06n",
	"oauth": {},
	"XR": {
		"region": "us-east-1",
		"scenes": {
			"crashsite": {
				"sceneConfig": {
					"sceneId": "91e48c154c0c4379ac796533504c95d9.scene",
					"region": "us-east-1",
					"projectName": "CCIC",
					"url": "https://sumerian.us-east-1.amazonaws.com/20180801/projects/CCIC/release/authTokens?sceneId=91e48c154c0c4379ac796533504c95d9.scene"
				}
			},
			"cleanroom": {
				"sceneConfig": {
					"sceneId": "2fccd16362724cc8a3e3ec1281b097be.scene",
					"region": "us-east-1",
					"projectName": "CCIC",
					"url": "https://sumerian.us-east-1.amazonaws.com/20180801/projects/CCIC/release/authTokens?sceneId=2fccd16362724cc8a3e3ec1281b097be.scene"
				}
			},
			"controlroom": {
				"sceneConfig": {
					"sceneId": "09bb78074a9045ccaebc1cfcb9fca9de.scene",
					"region": "us-east-1",
					"projectName": "CCIC",
					"url": "https://sumerian.us-east-1.amazonaws.com/20180801/projects/CCIC/release/authTokens?sceneId=09bb78074a9045ccaebc1cfcb9fca9de.scene"
				}
			},
			"officespace": {
				"sceneConfig": {
					"sceneId": "5e3ae17dc3cd4f0d9b3771688716a411.scene",
					"region": "us-east-1",
					"projectName": "CCIC",
					"url": "https://sumerian.us-east-1.amazonaws.com/20180801/projects/CCIC/release/authTokens?sceneId=5e3ae17dc3cd4f0d9b3771688716a411.scene"
				}
			},
			"courtroom": {
				"sceneConfig": {
					"sceneId": "9702454e0e63493c880c77731fb5d1a6.scene",
					"region": "us-east-1",
					"projectName": "CCIC",
					"url": "https://sumerian.us-east-1.amazonaws.com/20180801/projects/CCIC/release/authTokens?sceneId=9702454e0e63493c880c77731fb5d1a6.scene"
				}
			}
		}
	}
};

export default aws_exports
